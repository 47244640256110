import { addDays, endOfDay, startOfDay } from 'date-fns';
import { createSelector } from 'reselect';

import { dateRange, format, getPeriod, parseDate } from '../../shared/date.helper';
import { PeriodType } from '../../shared/interfaces';
import { AccountModel } from '../account/account.model';
import { getAccount } from './../account/account.service';
import { ScheduleFilterPeriod } from './page-filters.model';
import { getEmployeeScheduleFilters, getMyScheduleFilters, getScheduleFilters } from './page-filters.service';

export const getSelectedSchedulePeriod = createSelector(
  getScheduleFilters,
  getAccount,
  (filters, account): ScheduleFilterPeriod => {
    let period = filters.period;

    if (!period) {
      period = determineSchedulePeriod(new Date(), 'week', 'employee');
    }

    let minDate, maxDate;

    if (period && period.periodType === 'day') {
      minDate = parseDate(period.selectedDate + ' ' + account.day_start);
      minDate.setMinutes(0);
      maxDate = parseDate(period.selectedDate + ' ' + account.day_end);
      if (maxDate.getMinutes() > 0) {
        maxDate.setHours(maxDate.getHours() + 1);
      }
      if (maxDate <= minDate) {
        maxDate = addDays(maxDate, 1);
      }
    } else {
      minDate = startOfDay(parseDate(period.minDate));
      maxDate = endOfDay(parseDate(period.maxDate));
    }

    return {
      ...period,
      range: dateRange(minDate, maxDate),
    };
  }
);

export const getSelectedScheduleDate = createSelector(getSelectedSchedulePeriod, (periodFilter) => {
  if (!periodFilter || !periodFilter.selectedDate) {
    return format(new Date(), 'yyyy-MM-dd');
  }
  return periodFilter.selectedDate;
});

export const determineSchedulePeriod = (
  date: Date,
  periodType: PeriodType = 'week',
  viewType = 'employee',
  account?: AccountModel
): ScheduleFilterPeriod => {
  const period = getPeriod(periodType, date);
  const selectedDate = format(date, 'yyyy-MM-dd');

  if (periodType === 'day' && account) {
    const minDate = new Date(selectedDate + ' ' + account.day_start);
    const maxDate = new Date(selectedDate + ' ' + account.day_end);

    if (maxDate <= minDate) {
      period.end = addDays(period.end, 1);
    }
  }

  return {
    minDate: format(period.start, 'yyyy-MM-dd'),
    maxDate: format(period.end, 'yyyy-MM-dd'),
    selectedDate,
    periodType,
    viewType,
  };
};

export const getSelectedMyScheduleDate = createSelector(getMyScheduleFilters, (myScheduleFilters) => {
  if (!myScheduleFilters || !myScheduleFilters.date) {
    return format(new Date(), 'yyyy-MM-dd');
  }
  return myScheduleFilters.date;
});

export const getSelectedEmployeeScheduleDate = createSelector(getEmployeeScheduleFilters, (employeeScheduleFilters) => {
  if (!employeeScheduleFilters || !employeeScheduleFilters.date) {
    return format(new Date(), 'yyyy-MM-dd');
  }
  return employeeScheduleFilters.date;
});
